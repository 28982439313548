import React from "react"

import "./Faq.scss"
import CardAdvantages from "../CardAdvantages/CardAdvantages"

import { useTranslation } from "react-i18next"

const Faq = ({questions, hotLine}) => {
  const {t} = useTranslation();
  return (
    <div className="faq" id="faq">
      <h3>{t("faq.title")}</h3>

      <div className="content">
        {questions.map(({question, answer}, i) => {
          return (
            <div key={i}>
              <h5>{t(question)}</h5>
              <p dangerouslySetInnerHTML={{ __html: t(answer) }} />
            </div>
          )
        })}

        <CardAdvantages
          hotLine={hotLine}
        />
      </div>
    </div>
  )
}

export default Faq
